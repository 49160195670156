
import {useState} from 'react';
import './App.css';

const imgs = ['anime.gif', 'cars.gif', 'hiway.gif', 'pl.gif', 'akira.gif', 'cyb2.gif', 'canal.gif', 'nite.gif', 'petal.gif', 'rail.gif', 'grass.gif', 'grass2.gif', 'neon.gif', 'computer.gif', 'train.gif', 'tr2.gif', 'city.gif']

function App() {
  const [imgsrc, setImgSrc] = useState(null)
  const logo = './logo_900_test.png'
  return (
    <div className="App">
      <img src={imgsrc} className="App-bg" alt="logo" style={imgsrc ? {} : {opacity: 0}}/>
      <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%'}}>
        <div style={{position: 'relative', }} className={imgsrc ? "logo_holder blend" : "logo_holder"} onMouseLeave={() => {
          setImgSrc(null)
        }} onMouseEnter={() => {
          let item = imgs[Math.floor(Math.random()*imgs.length)];
          setImgSrc(item)
        }}>
        {/* <img src={'./logo_900_blue_v_3.png'} className="App-logo flicker0" alt="logo" /> */}
        <div className="l_circle" ></div>
        <div className="l_line l_center"></div>
        <div className="l_line l_left"></div>
        <div className="l_line l_right"></div>
        
        {/* <img src={'./logo_900_blue_v_3.png'} className="App-logo flicker1" alt="logo" /> */}
        <img src={'./pl_circle.png'} className="App-logo pcircle" alt="logo" />
        <img src={'./wordmark.png'} className="App-logo pcircle-word" alt="logo" />
        <div className="l_butt" onClick={() => {
          window.open('https://forms.gle/pZBqeStLRUYoECxJA', '_blank')
        }}>info</div>
        </div>
        </div>
        
      
    </div>
  );
}

export default App;
